export function getAmplifyConfiguration() {
    return {
        Auth: {
      
            // REQUIRED - Amazon Cognito Region
            region: 'us-east-1',
      
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: 'us-east-1_UejtrKkHc',//'us-east-1_1Ya8Uh77h', //'us-east-1_kRaIZKswg',
      
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: '1rdltuvti9bn23n212gb3f6g4s',
            //'7qlm00tcn4aqr7gnm8bnh7df5t', //'7sga02lldn6o2l99afi9k2sa5q',
                             
             identityPoolId: 'us-east-1:512cda41-dbcc-4be8-85ca-239adda8bfae',
             
             //'us-east-1:3b867c23-ad52-49cd-95b2-8da63f4bd8f9',// 'us-east-1:71c0f5ed-773d-4b39-a688-0e2cfb4d13ca',
      
            identityPoolRegion: 'us-east-1',
      
            // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
            mandatorySignIn: false,
      
            // OPTIONAL - Configuration for cookie storage
            // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
           /* cookieStorage: {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
                domain: '.typedpath.com',
            // OPTIONAL - Cookie path
                path: '/',
            // OPTIONAL - Cookie expiration in days
                expires: 365,
            // OPTIONAL - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
                secure: true
            },*/
      
            // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
            //authenticationFlowType: 'USER_PASSWORD_AUTH',
        },
        API: {
            endpoints: [
                {
                    name: "schemactCrudfunction",
                    //endpoint: "https://3vwe3fdgn0.execute-api.us-east-1.amazonaws.com/Prod"
                    endpoint: "https://schemactapi.testedsoftware.org"
                },
                {
                    name: "instanceCrudfunction",
                    //endpoint: "https://3vwe3fdgn0.execute-api.us-east-1.amazonaws.com/Prod"
                    endpoint: "https://instanceapi.testedsoftware.org"
                }

            ]
        }
      }
}